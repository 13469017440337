<template>
  <div class="content-wrapper">
    <div class="expense-statistics main-content">

      <div class="page-header">
        <h1>My Expense Statistics</h1>
      </div>

      <div class="chart-wrapper">
        <BarChart :options="chartOptions" />
      </div>

      <div class="expense-type-statistics-table">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th scope="col">Type Name</th>
              <th scope="col">Expense Count</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="expenseType in expenseTypes">
              <td>{{ expenseType.type_name }}</td>
              <td>{{ expenseType.count }}</td>
            </tr>
          </tbody>
        </table>
      </div>

    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import BarChart from '@/components/chart/BarChart.vue';
import Common from '@/lib/common';
import Financial from '@/lib/financial';

export default {
  name: 'MyExpenseStatistics',
  components: {
    BarChart,
  },
  data(){
    return {
      expenseTypes: [],
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
      }
    }
  },
  mounted(){
    this.loadUserExpenses();
  },
  methods:{
    formatDateTime(timestamp, format){
      const date = Common.formatDateTime(timestamp, format);
      return date;
    },
    async loadUserExpenses(){
      const loginInfo = Common.getLoginInfo();
      try{
        const currentUser = loginInfo['userId'];
        const allExpenses = await Financial.loadAllExpense(this.apiUrl, loginInfo);
        const expenses = allExpenses.filter(function(item){
          return item.employee_id == currentUser;
        });
        //For chart
        const typeLabels = [];
        const typeCounts = [];

        //For table
        const typeTableDatas = [];

        //Get Emplyoee which had expense record
        for(let i = 0; i < expenses.length; i++){
          const expense = expenses[i];

          if(!typeLabels.includes(expense.expense_type)){
            const type = {
              'type_name': expense.expense_type,
              'count': 0,
            };
            typeLabels.push(expense.expense_type);
            typeTableDatas.push(type);
          }
        }

        //Count employee's expenses
        for(let i = 0; i < typeLabels.length; i++){
          const label = typeLabels[i];
          const targetExpenses = expenses.filter(function(expense){
            if(label === expense.expense_type){
              return expense;
            }
          });
          const expensesCount = targetExpenses.length;
          typeCounts.push(expensesCount);

          //Get count to table data

          const expensesTableObject = typeTableDatas.find(function(type){
            if(label === type.type_name){
              return type;
            }
          });
          expensesTableObject['count'] = expensesCount;
        }

        this.expenseTypes = typeTableDatas;

        const chartData = {
          labels: typeLabels,
          datasets: [
            {
              label: 'Count of expense type',
              backgroundColor: '#343A40',
              data: typeCounts,
            }
          ],
        };
        this.setCurrentChartData(chartData);
      }catch(err){
        console.log(err);
      }
    },
    ...mapActions(['setCurrentChartData']),
  },
  computed: mapState({
   currentChartData: state => state.chart.currentChartData,
   apiUrl: state => state.apiUrl,
 }),
}
</script>

<style lang="scss">
.view-toolbars{
  display: flex;
  .fitler{
    flex: 1;
  }
  .el-icon-plus{
    font-size: 1.2em;
  }
}
.expense-type-statistics-table{
  margin-top: 50px;
}
</style>
