<template>
  <div class="report-wrapper">
    <div class="report-header">
      <h1 class="page-header">My Report</h1>
      <el-form ref="report-select-form" :model="form">
        <el-form-item label="Select Report" style="max-width: 550px;">
          <el-select v-model="form.report" placeholder="Select the report">
            <el-option label="My Expense Statistics" value="My Expense Statistics"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <StaffExpenseStatistics v-if="form.report === 'My Expense Statistics'"/>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import StaffExpenseStatistics from '@/views/report/MyExpenseStatistics.vue';

export default {
  name: 'MyReport',
  components: {
    StaffExpenseStatistics,
  },
  data(){
    return {
      form: {
        'report': 'My Expense Statistics'
      },
    }
  },
  computed: mapState({
   apiUrl: state => state.apiUrl,
 }),
}
</script>

<style lang="scss">
.report-wrapper{
  .page-header{
    h1{
      font-size: 1.4em;
    }
  }
  .report-header{
    margin-left: 350px;

    h1{
      margin-top: 50px;
    }
  }
}

</style>
